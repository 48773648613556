<template>
  <div class="lucky-draw-text box-padding">
    <h3>1、修理厂用户通过扫描达欧配件产品上的二维码（或发送短信987#防伪码#至12114），并完善账号信息（首次）即可获得对应的产品积分，配件扫码积分可登陆jf.phc-dow.com进行查询。</h3>
    <h3>2、参与“大大汽修”APP每日任务，每月任务。</h3>
    <p>1）首次注册成为“大大汽修”APP用户，可获得200积分。[ 注册用户定义为“修理厂”用户 ] </p>
    <p> 2）每月首次在“大大汽修”开工单可获得50积分。每月获得积分上限50积分 </p>
    <p>3）在“大大汽修”开工单，每日前5单，每单送积分20分。每日获得积分上限100积分</p>
    <p>4）每月邀请的第一位好友加入“大大汽修”成为注册用户可获得积分450分。每月获得积分上限450积分</p>
    <p>5）每日分享“大大汽修”到微信、QQ等等可获得5积分。每日获得积分上限为5积分</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.lucky-draw-text {
  margin-top: px2rem(69px);
  font-size: px2rem(28px);
  line-height: px2rem(50px);
  & h3 {
    font-weight: bold;
    margin: 0 0 px2rem(25px) 0;
  }
}
</style>
